
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nav-bar-ul {
    list-style-type: none;
    overflow: hidden;
    background-color: rgb(119, 153, 133);
    border-radius: 6px;
}
.navbar-item{
    width: 10px;
}
.main-content{
    padding:0px 5px;
}
li {
  float: left;
}
li a {  
    display: block;
    color: white;
    text-align: center;
    padding: 8px 8px;
    text-decoration: none;  
}
.active {
    background-color: rgb(201, 241, 19);
}
.footer-nav-bar-ul {
    list-style-type: none;
    overflow: hidden;
    background-color: rgb(101, 156, 124, 0.4);  
    border-radius: 6px;
}
.footer-active-copy {
    padding: 6px;
    float:right;
    color: rgb(248, 248, 251);
    margin: 5px;  
}
.footer-bar{  
    padding: 10px;
    position: fixed;
    bottom: 30px;
    width: 100%;
    height: 60px;
}

/* Home page */
.site-main {
    display: grid;
    grid-template-columns: 300px / 25% 40% ;
    grid-gap: 10px;
    background-color: #E6E6FA;
    background-size: cover;
    grid-auto-rows: 100px;
    text-align: center;
    align-content: center;
    margin: 50px;
    position: relative;
    bottom: 60px;
}
.right-area{
    background-color: #FFFAF0;
}
.left-area{
    background-color: #FFFAF0;
    padding: 10px; 
}

/* blog  page */

.ui form {
    background-color: rgb(229, 237, 233);
}
.cards:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.ui.link.cards{
    background-color: rgb(202, 199, 199);
}
.container{
    padding: 5px 5px;  
    align-content: center;
}
.family-photo{
    width: 40%;
    height: 40%;
    float: right;
    margin-left:15px;
    margin-right: 10px;
}
.about-me{ 
    text-align: center/left/right;
    margin: 10px;
    padding: 10px;
    background-color: rgb(243, 243, 237);
    padding-bottom: 60px;
    border-radius: 10px;
}
.welcome-home{
    font-style: oblique;
    font-size-adjust: 300%;
    margin: 10px;
}
.personal-section{
    margin:0px 5px;
    display: inline-flex; 
    margin-left: 1vw; 
   
}
@media screen and(max-width: 399px){
  .personal-section{
    width: 30px;
    height: 20px;
  }
}
.personal-title{
   margin-left: 20px;
   height: 40px;
}
.titles{  
    font-family: monospace;  
    font-size: 12px;   
}
.name{
    font-family: fantasy;
    font-weight: bold;
    font-size: 14px;
}

/* END Experience CSS Properties ####################################### */

.bc {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    padding: 40px;
    line-height: 1.8;
}
.bh {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-content: center;
}
.me-paragraph{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.8;
    font-weight: bold;
    padding: 30px;
    margin: 20px;
}
.edu-title{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 40px;
    padding: 10px;
}
.author{
    color: blue;
}
.individual-blog{
    margin:30px;
}
.sort{
    background-color: rgb(231, 219, 219);
}
.index-message{
    height: 78vh;
    width: auto;
    color:rgb(100, 46, 46); 
    margin-left:1%;
    margin-right:1%;
    font-family: fantasy;
    font-size: 8vh;
    background-image: url('./images/landscape.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;  
    position: relative;
    border-radius: 10px;
    
}
.quote{
    background-color: rgba(248, 213, 213, 0.6);
    background-size: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 26px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    color: rgb(61, 61, 61);
    margin: 30vh 0vh;
    width:auto;
    border-radius: 15%;
    border: 2px solid rgb(126, 126, 129);
    padding:3vh;  
}
.blog-cards-container{ 
    
    background-image: url('./images/blog-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 96.7vw;    
    align-content: center;
    padding: 10px;
    border-radius: 10px;
}
.blog-author{
    color: rgb(35, 187, 35);
    padding:30px;
    font-family: fantasy;
    background-color:rgb(0,0,0,0.4);
    align-content: center;  
}
.blog-car-header{
    background-color: rgba(122, 172, 180, 0.3);
    border-radius: 5px;
}
.blog-single-author{
    color: rgb(35, 187, 35); 
    font-family: fantasy;
    font-size:1vw;
    font-weight: bold;
    align-content: center;  
}
.blog-link-button{
    background-color: rgb(255, 255, 255);
    width: 100%;
}
.blog-link-button:hover{
    background-color: rgb(194, 227, 247);
    width: 100%;
}
.ui.medium.image.blog-card-image{
    height: 200px;
    width:100%;  
    padding: 20px;
    border-radius: 15%;
}
.ui.card.individual-card{
    margin: 20px;
    width: 20vw;
    background-color: rgba(246, 243, 241, 0.4);
}
.ui.one.cards>.card{
    width: 20vw;
    max-width: 350px;
    min-width: 310px;
}
.description{
    transition: 2s;
    opacity: 2;
    background-color: rgba(255, 255, 0, 0.219);
    width: 100%;
}
.project-card-description{
    transition: 2s;
    opacity: 2;
    background-color: rgba(255, 255, 0, 0.219);
    width: 90%;
}
.card-publish-date{
    float: bottom;
}
.content{
    align-content: center;
}
.resume{
    float: right;
}
Card.Content{
    height: 100px;  
}
.ui.link.cards{
    align-content: center;
    min-width: 60vh;
    padding: 8%;
}
.ui.link.cards.mobile{
    align-content: center;
    min-width: 60vh;
    padding: 14%;
    margin: 2% 3%;
    background-color: rgba(245, 232, 170, 0.507); 
    height: auto;
}
.projects-card-list{
    display: flex;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 20px;
    padding-bottom: 100px;
    width:244vw;
    background-image: url('./images/projectbackground.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.project-cards {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 8px;
    float:auto;
    margin: auto;
    background-color: rgb(230, 220, 130);
    position: relative;
}
.project-card{
    height: 300px;
}
.source-code-link{
    float: right;
    padding-right: 30px;
}
.project-card:hover{
    background-color: rgb(173, 191, 214, 0.7);
}
.project-card-image{
    height: 200px;
    width: 100%;
}
.project-card-content{
position: absolute;
bottom: 0;
padding-bottom: 20px;
width: 100%;
}
.ui.cards{
    display: inline-flex;
    background-color: rgb(173, 191, 214, 0.3);
}
.blog-container{
    align-content: center;
    padding: 1vw;
    padding-bottom: 100px;
    margin: 0.5vw 1vw;
}
.loading-message{
    color: rgb(38, 98, 51);
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    font-size: 60px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: papayawhip;
}
.ui.fluid.card{
    min-width: 200px;
}
.contact-container{
    margin: 1vw;
    background-color: rgb(240, 240, 218);
    padding:2vw;
    border-radius: 10px;
}
.social-logo{
    margin-left: 1vw;
    margin-right: 2vw;
    font-size: 30px;
}
.profile-picture{
    width: 110px;
    height: 100px;
    border-radius: 10px;
}
.projects-card-list{
    margin: 8px;
}